import { Curriculum } from '@domain/curriculum.type'

const curriculumMocks: Array<Curriculum> = [
  {
    createdAt: new Date(),
    description:
      'Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vestibulum id ligula porta felis euismod semper. Nulla vitae elit libero, a pharetra augue. Nullam quis risus eget urna mollis ornare vel eu leo. Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.',
    grades: [
      {
        id: '1',
        title: '1º Ano',
      },
    ],
    id: '1',
    microSkills: [],
    microSkillsCount: 139,
    rootIds: [],
    sections: [],
    standards: [
      {
        id: '1',
        title: 'BNCC',
      },
      {
        id: '2',
        title: 'IB',
      },
    ],
    subjects: [
      {
        id: '3',
        title: 'Matemática',
      },
    ],
    title: 'Matemática para 1º Ano',
    topics: [],
  },
]

const standardMocks = [
  {
    coverage: 100,
    id: '1',
    title: 'BNCC',
  },
  {
    coverage: 100,
    id: '2',
    title: 'IB',
  },
]

export { curriculumMocks, standardMocks }
