import type { Curriculum } from '@domain/curriculum.type'
import { css } from '@emotion/react'
import { Heading, Paragraph } from '@motrix/core'
import { styled } from '@motrix/tokens'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'

import ChipsStack from './ChipsStack'
import MicroSkillsCount from './MicroSkillsCount'

type CurriculumCardProps = Omit<
  Curriculum,
  'id' | 'microSkills' | 'topics' | 'sections' | 'rootIds'
> & { warningCount: number }

const CurriculumCard = ({
  description,
  grades,
  microSkillsCount,
  title,
  standards,
  subjects,
  warningCount,
}: CurriculumCardProps) => {
  return (
    <Paper
      css={cardStyles}
      sx={{
        '&:hover': {
          boxShadow: '0px 0px 10px -4px #374e80;',
        },
        boxShadow: '0px 0px 10px -7px rgba(0,0,0,0.75);',
      }}
      elevation={1}
    >
      <Box css={titleWrapperStyles}>
        <Heading size="h8">{title}</Heading>

        <MicroSkillsCount
          microSkillsCount={microSkillsCount || 0}
          size="small"
        />
      </Box>

      {description && (
        <StyledParagraph size="p6" color="neutral08">
          {description}
        </StyledParagraph>
      )}

      <Box css={chipsWrapperStyles}>
        <ChipsStack
          grades={grades}
          standards={standards}
          subjects={subjects}
          warningCount={warningCount}
        />
      </Box>
    </Paper>
  )
}

const cardStyles = css`
  padding: 16px;
  width: 100%;
  border-radius: 16px;
`

const titleWrapperStyles = css`
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  word-wrap: break-word;
`

const chipsWrapperStyles = css`
  margin-block-start: 13px;
`

const StyledParagraph = styled(Paragraph, {
  color: '$neutral07',
  marginTop: '$2',
  wordWrap: 'break-word',
})

export default CurriculumCard
