import type { FC, PropsWithChildren, ReactNode } from 'react'

import { css } from '@emotion/react'
import { colors } from '@motrix/tokens'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import type { Theme } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'

interface NavbarProps {
  topBar?: ReactNode
}

const Navbar = ({ children, topBar }: PropsWithChildren<NavbarProps>) => {
  const theme = useTheme()

  return (
    <Box css={navbarWrapperStyles(theme)}>
      {topBar && (
        <>
          {topBar}
          <Box css={dividerStyles}>
            <Divider />
          </Box>
        </>
      )}

      <Box css={container}>{children}</Box>
    </Box>
  )
}

const navbarWrapperStyles = (theme: Theme) => css`
  align-items: center;
  background-color: ${colors.neutral01};
  box-shadow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`

const container = css`
  align-items: center;
  display: flex;
  width: 100%;
  padding: 20px;
`

const dividerStyles = css`
  width: 100%;
`

export default Navbar
