import Skeleton, { SkeletonProps } from '@mui/material/Skeleton'

interface SkeletonListProps extends SkeletonProps {
  rows?: number
}

const SkeletonList = ({ rows = 5, ...props }: SkeletonListProps) => {
  const items = Array.from(Array(rows).keys())
  return (
    <>
      {items.map((element) => {
        return (
          <Skeleton
            {...props}
            key={`${element}-list-item-loader`}
            data-testid="skeleton"
          />
        )
      })}
    </>
  )
}

export default SkeletonList
