import type { ReactNode } from 'react'

import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'

interface NavbarEndSectionProps {
  children?: ReactNode
  isLoading?: boolean
}

const ActionsSection = ({ children, isLoading }: NavbarEndSectionProps) => {
  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        sx={{ marginInlineStart: 'auto' }}
        variant="rectangular"
        width={350}
      />
    )
  }

  return (
    <Box
      sx={{ alignItems: 'center', display: 'flex', marginInlineStart: 'auto' }}
    >
      {children}
    </Box>
  )
}

export default ActionsSection
