import type { ReactNode } from 'react'

import { Add, Button, Heading, Paragraph } from '@motrix/core'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'

interface NoResultsProps {
  buttonLabel: string
  description: string
  image: ReactNode
  onButtonClick: () => void
  subTitle?: string
  title?: string
}

const NoResultsCard = ({
  buttonLabel,
  image,
  onButtonClick,
  subTitle,
  title,
}: NoResultsProps) => {
  return (
    <Paper
      elevation={2}
      sx={{
        alignItems: 'center',
        borderRadius: '20px',
        padding: '32px 10px',
        textAlign: '-webkit-center',
      }}
    >
      <Box marginBottom="36px" minHeight="100px">
        {image}
      </Box>

      {title && <Heading size="h7"> {title} </Heading>}

      <Box
        marginBottom="20px"
        maxWidth="30ch"
        position="relative"
        marginTop="20px"
      >
        {subTitle && (
          <Paragraph size="p5" color="neutral06">
            {subTitle}
          </Paragraph>
        )}
      </Box>

      <Box>
        <Button onClick={onButtonClick} rightIcon={<Add />} size="md">
          {buttonLabel}
        </Button>
      </Box>
    </Paper>
  )
}

export default NoResultsCard
