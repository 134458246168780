import { useAuthentication } from '@motrix/authentication'

type SessionUser = {
  email: string
  image: string
  name: string
  organization: {
    id: string
    name: string
  }
}

const useSessionUser = (): SessionUser => {
  const { me } = useAuthentication()
  const email = me.email ?? 'usuario@email.com'
  const image = me.pictureUrl ?? 'image_url'
  const name = me.givenName ?? 'Usuário'
  const organization = {
    id: me.currentWorkspace.id ?? '',
    name: me.currentWorkspace.name ?? '',
  }

  return { email, image, name, organization }
}

export default useSessionUser
