import type { Curriculum } from '@domain/curriculum.type'
import { Heading } from '@motrix/core'

type CurriculumSectionHeaderProps = {
  curriculums: Array<Curriculum>
  title: string
}

const CurriculumSectionHeader = ({ title }: CurriculumSectionHeaderProps) => {
  return <Heading size="h7"> {title} </Heading>
}

export default CurriculumSectionHeader
