import type { ReactNode } from 'react'

import type { Curriculum } from '@domain/curriculum.type'
import { css } from '@emotion/react'
import { Heading } from '@motrix/core'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import { Theme, useTheme } from '@mui/material/styles'

interface NavbarTitleProps {
  children?: ReactNode
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  isLoading?: boolean
  name: Curriculum['title']
  onClick?: () => void
}

const NavbarTitle = ({
  children,
  component = 'h1',
  isLoading = false,
  name,
  onClick,
}: NavbarTitleProps) => {
  const theme = useTheme()
  const isClickable = !isLoading && onClick !== undefined
  const { wrapperStyles } = getStyles(isClickable, theme)

  if (isLoading) {
    return (
      <Box sx={wrapperStyles}>
        <Skeleton
          animation="wave"
          height={20}
          variant="text"
          width={250}
          data-testid="skeleton"
        />

        {children && (
          <Skeleton
            animation="wave"
            height={20}
            variant="rectangular"
            data-testid="skeleton"
          />
        )}
      </Box>
    )
  }

  return (
    <Box sx={wrapperStyles} onClick={onClick} data-testid="detail-navbar-title">
      <Heading as={component} size="h6" css={{ marginBottom: '2px' }}>
        {name} {isClickable && <InfoOutlinedIcon css={iconStyles(theme)} />}
      </Heading>

      {children}
    </Box>
  )
}

const getStyles = (isClickable: boolean, theme: Theme) => ({
  wrapperStyles: {
    '&& div': {
      cursor: isClickable ? 'pointer' : 'default',
    },
    '&:hover svg': {
      backgroundColor: theme.palette.grey[100],
    },
    cursor: isClickable ? 'pointer' : 'default',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '15px',
    maxWidth: '80%',
    wordBreak: 'break-word',
  },
})

const iconStyles = (theme: Theme) => css`
  color: ${theme.palette.info.main};
  padding: 5px;
  width: 24px;
  height: 24px;
  box-sizing: content-box;
  margin-left: 5px;
  border-radius: 100%;
  transition: background-color 0.2s ease-in-out;
`

export default NavbarTitle
